export default function Banner() {
    return (
      <div style={{
        backgroundColor: '#1E2124',
        color: '#93FF9A',
        padding: '0.5rem',
        textAlign: 'center',
        fontSize: '1.2rem',
      }}>
        © Insight Medical @ 2024. All Rights Reserved
      </div>
    );
  }
