

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './components/Navbar'
import Hero from './components/Hero'
 import Content from './components/content'
import List from './components/List'
import Vision from './components/VisionSection'
import Team from './components/Teamcomponent'
import Footer from './components/Footer'
import Banner from './components/Banner'

function App() {
  return (
   <div>
    <Navbar />
    <Hero />
    <Content /> 
    <List />  
    
    <Vision />
    <Team /> 
     <Footer /> 
    <Banner />
   </div>
  );
}

export default App;
