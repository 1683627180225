


import React from 'react';
import icon1 from '../assets/Icon3.png';
import icon2 from '../assets/Icon2.png';
import icon3 from '../assets/icon1.png';

const styles = {
  container: {
    backgroundColor: '#1C2234',
    padding: 'clamp(2rem, 5vw, 6rem) clamp(1rem, 4vw, 4rem)',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },
  heading: {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 700,
    marginBottom: 'clamp(3rem, 8vw, 6rem)',
    textAlign: 'center',
    lineHeight: 1.2,
    position: 'relative',
    zIndex: 2,
    padding: '0 1rem'
  },
  headingWhite: {
    color: 'white'
  },
  headingGreen: {
    color: 'rgb(74, 222, 128)'
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 'clamp(1rem, 2vw, 2rem)',
    maxWidth: '80rem',
    margin: '0 auto',
    position: 'relative',
    zIndex: 2,
    padding: '0 1rem',
    justifyContent: 'center'
  },
  feature: {
    flex: '1 1 300px',
    maxWidth: '400px',
    padding: 'clamp(1.5rem, 3vw, 2rem)',
    backgroundColor: 'rgba(30, 41, 59, 0.5)',
    borderRadius: '1rem',
    marginTop: '4rem',
    transition: 'transform 0.3s ease',
    ':hover': {
      transform: 'translateY(-5px)'
    }
  },
  featureIconWrapper: {
    position: 'relative',
    marginTop: '-4rem',
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  featureIconBg: {
    width: 'clamp(5rem, 8vw, 7rem)',
    height: 'clamp(5rem, 8vw, 7rem)',
    borderRadius: '9999px',
    backgroundColor: 'rgba(21, 128, 61, 0.5)',
    position: 'absolute',
    top: '-0.5rem',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  featureIcon: {
    width: 'clamp(4rem, 7vw, 6rem)',
    height: 'clamp(4rem, 7vw, 6rem)',
    borderRadius: '9999px',
    backgroundColor: 'rgb(74, 222, 128)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden'
  },
  iconImage: {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 10
  },
  featureTitle: {
    fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
    fontWeight: 600,
    color: 'rgb(74, 222, 128)',
    marginBottom: '1rem',
    textAlign: 'center'
  },
  featureDescription: {
    color: 'white',
    fontSize: 'clamp(1rem, 1.5vw, 1.125rem)',
    textAlign: 'center',
    lineHeight: 1.75
  }
};

const Feature = ({ iconSrc, title, description }) => (
  <div style={styles.feature}>
    <div style={styles.featureIconWrapper}>
      <div style={styles.featureIconBg}></div>
      <div style={styles.featureIcon}>
        <img 
          src={iconSrc} 
          alt={title} 
          style={styles.iconImage}
        />
      </div>
    </div>
    <h2 style={styles.featureTitle}>{title}</h2>
    <p style={styles.featureDescription}>{description}</p>
  </div>
);

const RadiologicalAI = () => {
  const features = [
    {
      iconSrc: icon1,
      title: "Clearer Insight",
      description: "Get clearer insight with intuitive highlighting and automatic sizing."
    },
    {
      iconSrc: icon2,
      title: "Improve Workflow",
      description: "Improve workflow efficiency with automatic chart prioritization, better routing, and easier collaboration."
    },
    {
      iconSrc: icon3,
      title: "Increase revenue",
      description: "Increase revenue through higher productivity and reduce costs with lower implementation and usage costs."
    }
  ];

  return (
    <div style={styles.container}>
      <div style={styles.overlay}></div>
      <h1 style={styles.heading}>
        <span style={styles.headingWhite}>Powerful </span>
        <span style={styles.headingGreen}>Radiological AI</span>
        <span style={styles.headingWhite}>, Reasonable </span>
        <span style={styles.headingGreen}>Cost</span>
      </h1>
      <div style={styles.featuresContainer}>
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default RadiologicalAI;