




import React from 'react';
import { Globe } from 'lucide-react';
import InsightLogo from '../assets/InsightLogo.svg'

const InsightLanding = () => {
  return (
    <div style={{ background: '#1e2736', minHeight: '50vh' }} className="text-white">
      <div className="container py-5">
        {/* Top Card */}
        <div className="row mb-5">
          <div className="col-12">
            <div style={{ background: 'rgba(40, 48, 70, 0.5)' }} className="card border-0 rounded-4">
              <div className="card-body text-center p-5">
                <p className="mb-4 text-white" style={{ fontSize: '1.1rem' }}>
                  Contact us to learn more and see for yourself how we can help you and your team better meet your workflow, revenue and quality goals.
                </p>
                <button  className="btn fs-5 fw-bold"
              style={{
                backgroundColor: '#3DC2FF',
                color: 'black'
              }}>
                  See For Yourself »
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Logo & Info */}
        <div className="text-center mb-5">
          <img src={InsightLogo} alt="Insight Medical" className="mb-4" style={{ height: '70px', }} />
          <p className="text-white mb-4">
            Headquartered in the US, with development teams in<br />
            India and the US.
          </p>
          <a href="mailto:info@insightmedical.ai" className="text-white text-decoration-none d-inline-flex align-items-center">
            <Globe size={24} className="me-2" style={{ color: '#10b981' }} />
            <span class="fs-5 text-decoration-underline">info@insightmedical.ai</span>

          </a>
        </div>

        {/* Navigation */}
        <div className="text-center">
          <h2 className="h4 mb-4">About Us</h2>
          <div className="mb-4">
            {['Vision', 'Challenges', 'Team'].map((item) => (
              <button key={item} className="btn text-white border-0 mx-2">
                {item}
              </button>
            ))}
          </div>

         
        </div>
      </div>
    </div>
  );
};

export default InsightLanding;