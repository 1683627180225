import React from 'react';
import Frame from '../assets/Frame12.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const VisionSection = () => {
  return (
    <div className="min-vh-50 p-4 py-5" style={{ backgroundColor: '#1a1f2e' }}>
      <div className="container">
        <div className="row align-items-start gy-4">
          {/* Left Content */}
          <div className="col-lg-5 col-md-6 pe-md-4 ps-md-4">
            <h1 className="display-4 fw-bold text-white mb-4">
              Our<span className="text-custom" style={{color: '#4ADE80'}}> Vision</span>
            </h1>
            
            <h2 className="h3 fw-semibold mb-4" style={{color: '#4ADE80'}}>
              AI-Driven Future of Radiologists
            </h2>
            
            <p className="text-light fs-5 lh-base">
              Become the leading provider of artificial intelligence-enabled tools that best support radiologists' goals of being able to analyze more charts confidentially than ever before.
            </p>
          </div>
          
          {/* Right Image Container */}
          <div className="col-lg-7 col-md-6 px-md-4">
            <div className="rounded-4 p-3 p-md-4">
              <div className="rounded-3 overflow-hidden">
                <img
                  src={Frame}
                  alt="AI in Healthcare"
                  className="img-fluid w-100"
                  style={{
                    maxHeight: '600px',
                    objectFit: 'cover'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionSection;