

import React from 'react';
// import Frame2 from '../assets/Frame2.png';
import Frame3 from '../assets/Frame3.png';
import Frame4 from '../assets/Frame4.png';
// import Frame5 from '../assets/Frame5.png';

const TeamComponent = () => {
  const teamMembers = [
    // {
    //   name: "Chris Riche",
    //   position: "Chief Executive Officer",
    //   quote: "Lorem ipsum dolor sit amet consectetur.  at scelerisque augue vitae eget. Nec integer quisque blandit suspendisse. um dolor sit amet consectetur.  at scelerisque augue vitae eget. Nec integer quisque blandit suspendisse.",
    //   image: Frame2
    // },
    {
      name: "Ranga Maramraju",
      position: "Principal",
      quote: "Serial entrepreneur with extensive executive-level consulting and leadership experience in the technology sector.",
      image: Frame4
    },
    // {
    //   name: "Raja Mullapudi",
    //   position: "Chief Technical Architect",
    //   quote: "AI and image processing expert with significant algorithm development and organizational leadership experience; Including prior experience founding a successful AI company.",
    //   image: Frame5
    // },
    {
      name: "Srikant Reganti",
      position: "Principal",
      quote: "Investment banking and international law expert  with significant experience in North America, the Middle East, and Asia.",
      image: Frame3
    }
  ];

  return (
    <div style={{ backgroundColor: '#1C2331', minHeight: '70vh', padding: '80px 0' }}>
  <div className="container">
  <h1 className="text-center fw-bold mb-5 display-4 display-md-3 display-lg-2">
      <span className="text-white">Our </span>
      <span style={{ color: '#4ADE80' }}>Team</span>
      <span className="text-white">, Your </span>
      <span style={{ color: '#4ADE80' }}>Partners</span>
    </h1>
        
        <div className="row g-4">
          {teamMembers.map((member, index) => (
            <div key={index} className="col-12 col-md-6">
              <div style={{
                backgroundColor: '#242B3D',
                borderRadius: '16px',
                border: '1px solid #4ADE80',
                padding: '32px',
                height: '100%',
                width: '100%'
              }}>
                <div className="d-flex flex-column align-items-start">
                  <div className="position-relative mb-4" style={{ 
                    width: '100%',
                    maxWidth: '150px'  // Changed from 200px to 150px
                  }}>
                    <div style={{
                      overflow: 'hidden',
                      width: '100%',
                      paddingBottom: '100%',
                      position: 'relative',
                    }}>
                      <img
                        src={member.image}
                        alt={`${member.name}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                    <div 
                     className="position-absolute top-0 start-0 w-100 h-100"
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <h2 className="text-white fs-3 mb-2 fw-medium">{member.name}</h2>
                    <p className="text-white mb-3">{member.position}</p>
                    <p className="text-white mb-0" style={{ opacity: 0.7, lineHeight: '1.6' }}>
                      "{member.quote}"
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamComponent;