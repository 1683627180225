


import React from 'react';
 import Rediologist from '../assets/Frame11.png';

const RadiologyPage = () => {
  return (
    <div style={{
      backgroundColor: '#1a1f2e',
      minHeight: '50vh',
      padding: '40px 20px'
    }}>
      <div className="container-fluid container-lg">
        {/* Header Section */}
        <div className="text-center mb-4 mb-md-5">
          <h1 style={{
            color: '#ffffff',
            fontSize: 'clamp(32px, 5vw, 48px)',
            fontWeight: 'bold',
            marginBottom: '8px',
            wordBreak: 'break-word'
          }}>
            Radiologists are under
          </h1>
          <h2 style={{
            color: '#40F99B',
            fontSize: 'clamp(32px, 5vw, 48px)',
            fontWeight: 'bold',
            wordBreak: 'break-word'
          }}>
            more and more pressure
          </h2>
        </div>

        {/* Content Section */}
        <div className="row justify-content-center align-items-stretch gy-4 gy-lg-0" style={{ gap: '50px' }}>
          {/* Image Column */}
          <div className="col-12 col-lg-6 mb-4 mb-lg-0 px-lg-4">
            <div className="position-relative w-100 h-100">
              <img
                src={Rediologist}
                alt="Radiologist working at computer"
                className="img-fluid w-100 rounded"
                style={{
                  minHeight: 'auto', // Changed from 300px to auto
                  height: 'auto',    // Added height: auto
                  maxHeight: '500px',
                  objectFit: 'contain', // Changed from cover to contain
                  width: '100%',     // Added explicit width
                  '@media (max-width: 400px)': {
                    minHeight: 'auto',
                    maxHeight: '300px' // Reduced max-height for very small screens
                  }
                }}
              />
            </div>
          </div>

          {/* Text Column */}
          <div className="col-12 col-lg-5 d-flex align-items-stretch px-lg-4">
            <div className="d-flex flex-column justify-content-between w-100 gap-4">
              <div style={{
                backgroundColor: 'rgba(30, 41, 59, 0.5)',
                borderRadius: '24px',
                padding: 'clamp(16px, 3vw, 34px)', // Reduced minimum padding
                height: 'auto', // Changed from 100% to auto
                marginBottom: '20px' // Added explicit margin bottom
              }}>
                <p style={{
                  color: '#cbd5e1',
                  fontSize: 'clamp(14px, 2vw, 18px)', // Reduced minimum font size
                  lineHeight: '1.6',
                  margin: 0,
                }}>
                  If you are like many radiologists, it seems you need to read more
                  charts every year in the same amount of time. However, given the
                  importance of your analysis, you don't want to trade quality for
                  speed...
                </p>
              </div>

              <div style={{
                backgroundColor: 'rgba(30, 41, 59, 0.5)',
                borderRadius: '24px',
                padding: 'clamp(16px, 3vw, 34px)', // Reduced minimum padding
                height: 'auto' // Changed from 100% to auto
              }}>
                <p style={{
                  color: '#cbd5e1',
                  fontSize: 'clamp(14px, 2vw, 18px)', // Reduced minimum font size
                  lineHeight: '1.6',
                  margin: 0,
                }}>
                  Two resources are needed: First, to speed up routine tasks like
                  prioritization and measurement. Second, the tools to help you gain
                  the insight you want more quickly while reducing the chance of
                  missing anything important...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadiologyPage;