

// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// // Note: Make sure to import bootstrap.bundle.min.js for the hamburger menu to work
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import InsightLogo from '../assets/InsightLogo.svg';

// const Navbar = () => {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
//       <div className="container">
//         {/* Logo */}
//         <a className="navbar-brand ms-0 ps-2" href="#">
//           <img
//             src={InsightLogo}
//             alt="Insight Medical Logo"
//             style={{ height: '70px', }}
//           />
//         </a>

//         {/* Hamburger Menu Button */}
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarContent"
//           aria-controls="navbarContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         {/* Navigation Items */}
//         <div className="collapse navbar-collapse" id="navbarContent">
//           <div className="navbar-nav ms-auto d-flex flex-row align-items-center gap-3">
//             <a className="nav-link fw-bold fs-5 text-white" href="#about">
//               About Us
//             </a>
//             <a className="nav-link fw-bold fs-5 text-white" href="#contact">
//               Contact Us
//             </a>
//             <button
//               className="btn fs-5 fw-bold"
//               style={{
//                 backgroundColor: '#3DC2FF',
//                 color: 'black'
//               }}
//             >
//               Request Demo
//             </button>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
 import InsightLogo from '../assets/InsightLogo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const styles = {
    navbarBrand: {
      padding: '0.5rem 1rem',
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center'
    },
    logo: {
      maxHeight: '70px',
      height: 'auto',
      width: 'auto',
      '@media (max-width: 768px)': {
        maxHeight: '50px'
      },
      '@media (max-width: 576px)': {
        maxHeight: '40px'
      }
    },
    menuButton: {
      border: 'none',
      padding: '0.5rem',
      background: 'transparent',
      transition: 'all 0.3s ease'
    },
    navLink: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      color: 'white',
      transition: 'color 0.3s ease',
      textDecoration: 'none',
      padding: '0.5rem 1rem'
    },
    demoButton: {
      backgroundColor: '#3DC2FF',
      color: 'black',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      padding: '0.5rem 1.5rem',
      border: 'none',
      borderRadius: '0.375rem',
      transition: 'background-color 0.3s ease'
    }
  };

  const NavItems = ({ isMobile = false }) => (
    <div className={`${isMobile ? 'd-flex flex-column' : 'navbar-nav ms-auto d-flex align-items-center'} gap-3`}>
      <a 
        className="nav-link" 
        href="#about"
        style={styles.navLink}
        onMouseOver={(e) => e.target.style.color = '#3DC2FF'}
        onMouseOut={(e) => e.target.style.color = 'white'}
        onClick={() => isMobile && setIsOpen(false)}
      >
        About Us
      </a>
      <a 
        className="nav-link" 
        href="#contact"
        style={styles.navLink}
        onMouseOver={(e) => e.target.style.color = '#3DC2FF'}
        onMouseOut={(e) => e.target.style.color = 'white'}
        onClick={() => isMobile && setIsOpen(false)}
      >
        Contact Us
      </a>
      <button
        className={`btn ${isMobile ? 'w-100' : ''}`}
        style={styles.demoButton}
        onMouseOver={(e) => e.target.style.backgroundColor = '#2BA8E6'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#3DC2FF'}
        onClick={() => isMobile && setIsOpen(false)}
      >
        Request Demo
      </button>
    </div>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container position-relative">
        {/* Logo with responsive styling */}
        <a className="navbar-brand d-flex align-items-center" href="#" style={styles.navbarBrand}>
          <picture>
            <source 
              media="(max-width: 576px)" 
              srcSet={InsightLogo}
            />
            <source 
              media="(max-width: 768px)" 
              srcSet={InsightLogo}
            />
            <img
              src={InsightLogo}
              alt="Insight Medical Logo"
              className="img-fluid"
              style={{
                maxHeight: '70px',
                marginLeft:'-10%',
                width: 'auto',
                height: 'auto',
                '@media (max-width: 768px)': {
                  maxHeight: '50px'
                },
                '@media (max-width: 576px)': {
                  maxHeight: '40px'
                }
              }}
            />
          </picture>
        </a>

        {/* Hamburger Menu Button */}
        <button
          className="navbar-toggler border-0 d-lg-none"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarContent"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
          style={styles.menuButton}
        >
          {isOpen ? (
            <X size={24} color="white" />
          ) : (
            <Menu size={24} color="white" />
          )}
        </button>

        {/* Desktop Navigation */}
        <div className="collapse navbar-collapse" id="navbarContent">
          <NavItems />
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <div 
            className="d-lg-none w-100 position-absolute bg-dark"
            style={{
              top: '100%',
              left: 0,
              right: 0,
              padding: '1rem',
              transition: 'all 0.3s ease',
              zIndex: 1000
            }}
          >
            <NavItems isMobile={true} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;