

import React from 'react';
import InsightAi from '../assets/InsightAi.png';
import "./Hero.css"

const Hero = () => {
 
  return (
    <div className="container-fluid p-0">
      <div className="position-relative">
        <img
          src={InsightAi}
          alt="Radiologist viewing medical scans"
           className="w-100 vh-100 "
          style={{
            objectPosition: 'left center'
          }}
        />
        
        <div className="position-absolute bottom-0 start-0 w-100">
          <div className="bg-dark bg-opacity-50 p-4 p-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <h1 className="hero-heading fw-900 mb-3 text-start">
                    <span className="text-white">Maximum </span>
                    <span style={{color: '#40F99B'}}>Productivity</span>
                    <span className="text-white">, Minimum </span>
                    <span style={{color: '#40F99B'}}>Stress</span>
                  </h1>
                  
                  <p className="text-white fs-4 mb-4 text-start">
                    Your Radiological AI assistant to help handle routine tasks, speed insight, and reduce stress.
                  </p>
                  
                  <div className="text-start">
                    <button 
                      className="btn btn-lg px-4 py-2" 
                      style={{ 
                        backgroundColor: '#3DC2FF', 
                        color: 'black', 
                        fontWeight: '700',
                        fontSize: '1.25rem'
                      }}
                    >
                      Learn More »
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;